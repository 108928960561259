<template>
  <h1>
    cargando
  </h1>
</template>

<script>
import { mapMutations } from 'vuex';
import Api from '~/services/api'
export default {
    data(){
        return {
            FS_LOCAL:"http://full-empresa.localhost/login-full-empresa-iaf",
            FS_SANDBOX:"https://fs-sandbox.fullsmart.work/login-full-empresa-iaf", // ya no existe
            FS_PRODUCCION:"https://iaf.fullsmartyes.com/login-full-empresa-iaf"
        }
    },
    created () {
        this.loginFs()
    },
    methods: {
        ...mapMutations({
            SET_TOKEN: 'auth/SET_TOKEN' 
        }),
        async loginFs() {
            const token = this.$route.params.id
            this.SET_TOKEN({token: token})
            await this.$store.dispatch('auth/FETCH_USER');
            
            this.redirectFs()
        },
        obtenerSubdominio(){
            let domain = window.location.host;
            let sub_dominio = domain.split('.').shift()
            return this.subdominio = sub_dominio
        },
        redirectFs(){
            this.obtenerSubdominio()
            const user = 1
            let ruta = ''
            if (this.subdominio == 'localhost:8080') {
                ruta = `${this.FS_LOCAL}/${user}`
            }else if(this.subdominio == 'full-smart-pruebas'){
                ruta = `${this.FS_SANDBOX}/${user}`
            }else{
                ruta = `${this.FS_PRODUCCION}/${user}`
            }
            window.open(ruta, "_self")
        }
        
    },
}
</script>
<style>

</style>